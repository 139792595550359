(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name services.factory:RefereeList
   *
   * @description
   *
   */
  angular
    .module('neo.services')
    .factory('Classificacio', Classificacio);

  function Classificacio($resource, EnvironmentConfig) {
    return $resource(EnvironmentConfig.API + '/public/classificacions/:idCampionat', {idCampionat: '@_idCampionat'}, {
      update: {
        method: 'PUT'
      },
      query: {
        method: 'GET',
        isArray: true
      }
    });
  }
}());
